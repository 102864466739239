body,
#root {
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  min-width: 100vw;
  margin: 0;
  padding: 0;
  font-family: Inter, Roboto, Helvetica, Arial, sans-serif;
  overflow: hidden;
}
