@import '~stream-chat-react/dist/css/v2/index.css';

div.str-chat-channel.str-chat__channel,
div.str-chat.str-chat__channel-list {
  flex: 1 0 0;
}

div.str-chat-channel.str-chat__channel {
  border-radius: 0 16px 16px 0;
}

div.str-chat.str-chat__channel-list {
  border-radius: 0 0 0 16px;
}

div.str-chat__channel > div.str-chat__container > div.str-chat__main-panel {
  border-radius: 0 16px 16px 0;
}

@media (max-width: 1240px) {
  div.str-chat-channel.str-chat__channel {
    border-radius: 0;
  }

  div.str-chat.str-chat__channel-list {
    border-radius: 0;
  }

  div.str-chat__channel > div.str-chat__container > div.str-chat__main-panel {
    border-radius: 0;
  }

  div.str-chat__channel
    > div.str-chat__container
    > div.str-chat__main-panel
    > div.str-chat__header-livestream {
    border-radius: 0;
  }

  div.str-chat__message-input {
    border-radius: 0;
  }
}

/* Header */
div.str-chat__channel
  > div.str-chat__container
  > div.str-chat__main-panel
  > div.str-chat__header-livestream {
  border-radius: 0 16px 0 0;
  padding: 12px 24px 12px 16px;
  background-color: white;
  box-shadow: none;
  border-bottom: 1px solid #ebebeb;
}

/* Message inputbox */
div.str-chat__message-input {
  border-radius: 0 0 16px 0;
}

/* Avatar */
div.str-chat__message.str-chat__message--me > div.str-chat__message-sender-avatar {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 20px !important;
}

div.str-chat__message.str-chat__message--other > div.str-chat__message-sender-avatar {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 20px !important;
}

/* Message bubble */
.str-chat__message.str-chat__message--me .str-chat__message-bubble,
.str-chat__message.str-chat__message--other .str-chat__message-bubble {
  background: rgba(0, 0, 0, 0.04) !important;
}

p.str-chat__channel-list-empty-v1 {
  display: none;
}

button.str-chat__message-simple__actions__action.str-chat__message-simple__actions__action--thread.str-chat__message-reply-in-thread-button {
  display: none !important;
}
